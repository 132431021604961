<template>
  <div>
    <vx-card title="Retenciones de IVA, ISR.">
      <vs-table :data="monthlyRetentions" no-data-text="No hay información disponible">
        <template slot="thead">
          <vs-th>PERIODO</vs-th>
          <vs-th>INTERESES PAGADOS</vs-th>
          <vs-th>IVA PAGADO</vs-th>
          <vs-th>IVA RETENIDO</vs-th>
          <vs-th>ISR RETENIDO</vs-th>
          <vs-th>ACCIONES</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="monthlyRetention in data" v-if="monthlyRetention.interest_payments > 0">
            <vs-td>{{monthlyRetention.period}}</vs-td>
            <vs-td>{{monthlyRetention.interest_payments|currency}}</vs-td>
            <vs-td>{{monthlyRetention.iva_payments|currency}}</vs-td>
            <vs-td>{{monthlyRetention.iva_detained|currency}}</vs-td>
            <vs-td>{{monthlyRetention.isr_detained|currency}}</vs-td>
            <vs-td style="width: 300px;">
              <div class="flex flex-col gap-2">
              <vs-button
                  :disabled="monthlyRetention.invoice_counter === 0"
                  @click="downloadZip(monthlyRetention)">
                Descargar zip ({{monthlyRetention.invoice_counter}} facturas)
              </vs-button>
              <vs-button
                  color="dark"
                  :disabled="monthlyRetention.invoice_counter === 0"
                  @click="downloadExcel(monthlyRetention)">
                Descargar archivo de integración
              </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import moment from "moment";
import {mapState} from "vuex";

export default {
  name: "MonthlyRetentionPage",
  props: ["applicant"],
  data: () => ({
    monthlyRetentions: []
  }),
  computed: {
    ...mapState("auth", ["access_token"])
  },
  async mounted() {
    const {data} = await axios.get(`/api/applicant/${this.user.client_id}`)
    this.applicant = data;

    if (!this.applicant.required_invoice) {
      this.$router.back();
    }

    let startDate = moment().subtract(6, 'months').format('YYYY-MM-DD');
    let endDate = moment().format('YYYY-MM-DD');
    this.$vs.loading();

    try {
      const {data} = await axios.get(`/api/monthly-retention/aid/${this.user.client_id}?start_date=${startDate}&end_date=${endDate}`)
      this.monthlyRetentions = data;
    } finally {
      this.$vs.loading.close();
    }
  },
  filters: {
    currency(val) {
      return new Intl.NumberFormat("es-mx", {
        style: 'currency',
        currency: 'MXN'
      }).format(val)
    }
  },
  methods: {
    downloadZip(monthlyRetention) {
      window.open(`${this.ApiDomain}/api/monthly-retention/${monthlyRetention.id}/invoice/download`, '_blank');
    },
    downloadExcel(monthlyRetention) {
      let period = `01-${monthlyRetention.period}`;
      let applicantId = monthlyRetention.applicant_id;
      let month = moment(period).format("M")
      let year = moment(period).format("Y")

      let params = "?applicant_id=" + applicantId;
      params += "&month=" + month;
      params += "&e=" + period;
      params += "&year=" + year;
      params += "&access_token=" + this.access_token;

      window.open(`${this.ApiDomain}/api/monthly-retention/invoice-integration/excel${params}`, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
